import React from 'react';
import TextWithPic from '../../common/TextWithPic';

const IntroTexts = () => {

    return (
        <div id='intro-texts' className='margin-top-150 max-width-content'>
            <TextWithPic href="/images/square-stub.png" alt="square stub" textAlign='right'>
                <p>
                    COP29, the most consequential climate change conference in the world, is set to be hosted in Baku, Azerbaijan: a notoriously corrupt, genocidal petro-state under an extreme authoritarian regime committing systemic human rights violations that rank it as less free than bordering Iran and close ally Russia, also the sole reason why they will host this prestigious conference.
                </p>
                <br/>
                <p>
                    The UNFCCC has not shared that Azerbaijani society is critically unsafe for activists, journalists, Armenians, the disabled and immunocompromised, and the LGBTQ+ community, all of whom face potential violence, harassment, and persecution. In addition, contaminated water and poor air quality in Baku present tangible health risks, protests of which by locals are violently suppressed.
                </p>
            </TextWithPic>
            <TextWithPic href="/images/square-stub.png" alt="square stub" textAlign='left'>
                <p>
                    Not only that, but Azerbaijan is also abusing its power as host to legitimize and help fund a longstanding, ongoing campaign of genocide and total erasure of neighboring Armenians. 
                </p>
                <br/>
                <p>
                    Even the "Green Energy Zone" Azerbaijan is exploiting as a front for renewable energy investments is Nagorno-Karabakh: ancestral Armenian land that Azerbaijan ethnically cleansed of over 150,000 Indigenous Armenians. It is now a domestic priority for Baku to destroy the thousands of years of Armenian cultural heritage in the region to serve a deep-seated nationalist agenda of cultural appropriation and expansionism.
                </p>
            </TextWithPic>
        </div>
    );
};

export default IntroTexts;
