import React from 'react';

const TakeActionBtn = ({ children }) => {
    return (
        <div className='take-action'>
            Take action
        </div>
    );
};

export default TakeActionBtn;
