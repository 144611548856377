import React from 'react';
import TakeActionBtn from '../TakeActionBtn';

const Text = ({ children, margingAfterBtn=0, className='', addTakeActionBtn=true }) => {
    return (
        <div className={'text-blck ' + className}>
            <div className='text'>
                {children}
            </div>
            { addTakeActionBtn && <TakeActionBtn/> }
            { addTakeActionBtn && <div style={{height: 'var(--size-150-px)'}}/> }
        </div>
    );
};

export default Text;
