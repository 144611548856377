import React from 'react';

const Navbar = () => {

    return (
        <div id="navbar">
            <div className='home'>
                Home
            </div>
            <div className='middle-links'>
                <div className='why-move'>
                    Why move?
                </div>
                <div className='supporters'>
                    Supporters
                </div>
                <div className='how-to-support'>
                    How to support?
                </div>
            </div>
            <div className='take-action'>
                Take action
            </div>            
        </div>
    );
};

export default Navbar;
