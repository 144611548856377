import React from 'react';

const TextWithPic = ({ children, href, textAlign='left', alt='unknown' }) => {

    return (
        <div className={'text-with-pic ' + textAlign}>
            <div className='text part'>
                {children}
            </div>
            <img className='pic part' src={href} alt={alt} />
        </div>
    );
};

export default TextWithPic;
