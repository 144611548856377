import React from 'react';

const Why = () => {

    return (
        <div>
        </div>
    );
};

export default Why;
