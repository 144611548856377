import React from 'react';

const Supporters = () => {

    return (
        <div>
        </div>
    );
};

export default Supporters;
