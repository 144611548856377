import React from 'react';
import Home from '../Home';
import IntroTexts from '../Home/IntroTexts';
import Why from '../Why';
import Supporters from '../Supporters';
import HowHelp from '../HowHelp';
import Text from '../../common/Text';
import Citation from '../Home/Citation';

const Main = () => {

    return (
        <div id='main'>
            <Home/>
            <IntroTexts/>
            <Citation/>
            <Text className='margin-top-150 first-text-blck'>
                End Fossil Fuels at COP29
            </Text>
            <Why/>
            <Supporters/>
            <HowHelp/>
        </div>
    );
};

export default Main;
